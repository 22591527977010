<template>
  <el-row style="height: 100%">
    <el-col :span="24">
      <el-result v-if="showError" icon="warning" title="当前链接已失效，请联系易诺工作人员" style="height: 100%">
        <template #extra>
          <el-button type="primary" @click="$router.push('/homepage')">确认</el-button>
        </template>
      </el-result>

    </el-col>
  </el-row>
</template>

<script setup>

import {getCurrentInstance, ref} from "vue";
import {ElLoading} from "element-plus";
import {generateShortLink} from '@/request/api/common'

let {proxy} = getCurrentInstance()
let loading = ElLoading.service({lock: true, text: '正在加载页面，请稍后...'})
let showError = ref(false)
if (proxy.$route.params && proxy.$route.params.target) {
  generateShortLink(proxy.$route.params.target, proxy.$route.params.adset ? proxy.$route.params.adset : null).then(res => {
    console.log(res)
    if (res.url && res.code && res.code === 200) {
      window.location.href = res.url
      return loading.close()
    }
    showError.value = true
    loading.close()
  }).catch(() => {
    showError.value = true
    loading.close()
  })
} else {
  showError.value = true
  loading.close()
}

</script>